/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'

var _self: PropertyItem;

class PropertyItem extends Component<any, any> {

    constructor(props){
        super(props);

        this.state = props;
    }
  
    render(){
        const propertyData = this.state;    
        return(
           <Link
            to={`/properties/detail/${propertyData.id}`}
            className='card border border-3 border-gray-300 p-4 shadow-sm'
            >
            <div className='bgi-no-repeat bgi-size-cover rounded min-h-250px' style={{"backgroundImage":`url(${propertyData.icon})`}}>
            </div>
               
            <div className='card-body'>
            
                <div className='card-title m-0'>
                <div className='fs-3 fw-bolder text-dark'>{propertyData.title}</div>
                </div>

                <div className='card-toolbar'>
                <span className={`badge badge-light-${propertyData.badgeColor} fw-bolder me-auto py-3`}>
                    {propertyData.status}
                </span>
                </div>

                <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{propertyData.description}</p>
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-wrap mb-5'>
                        <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-7 mb-3'>
                            <div className='fs-6 text-gray-800 fw-bolder'>{propertyData.date}</div>
                            <div className='fw-bold text-gray-400'>Date listed</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded py-3 px-4 mb-3'>
                            <div className='fs-6 text-gray-800 fw-bolder'>£{propertyData.price}</div>
                            <div className='fw-bold text-gray-400'>per month</div>
                        </div>
                    </div>  
                </div> 
                <div className='col-sm-12 col-xl-12 mt-2'>
                    <Link to={`/properties/detail/${propertyData.id}`} className="btn btn-primary w-100">View property</Link>
                </div>            
            </div>
            </Link>
        )
    }
}

export default PropertyItem;