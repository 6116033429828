/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import {PublicHeader} from './PublicHeader'
import {KTSVG} from '../../_metronic/helpers'
import LetOffer from '../pages/forms/LetOffer'
import BookViewing from '../pages/forms/BookViewing'
import BookValuation from '../pages/forms/BookValuation'
import AskQuestion from '../pages/forms/AskQuestion'

const API_URL = process.env.REACT_APP_SERVER_HOST
const GET_PROPERTY_URL = `${API_URL}property`
const GET_TENANCIES_URL = `${API_URL}tenancies`
const GET_PROPERTY_DOCUMENT_URL = `${API_URL}documents`
const GET_PROPERTY_OFFER_URL = `${API_URL}offers`
const GET_PROPERTY_DOWNLOAD_URL = `${API_URL}download`

var _self: PropertyDetail

class PropertyDetail extends Component<any, any> {
  constructor(props) {
    super(props)

    const propertyId = this.props.match.params.id

    this.state = {
      propertyData: [],
      tenanciesData: [],
      offerData: [],
      documentData: [],
      propertyImageList: [],
      isLoader: true,
      propertyId: propertyId,
    }

    _self = this
    this.fetchProperty = this.fetchProperty.bind(this)
  }

  componentDidMount() {
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }
    this.fetchProperty(requestBody)
  }

  fetchProperty(requestBody) {
    _self.setState({
      isLoader: true,
    })
    axios.get(`${GET_PROPERTY_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'info',
          propertyData: response.data._embedded ? response.data._embedded[0] : [],
          propertyImageList: response.data._embedded
            ? response.data._embedded[0]['propertyImageList']
            : [],
        })
      }
    })
  }

  getPropertyTenancies() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }

    axios.get(`${GET_TENANCIES_URL}`, {params: requestBody}).then(function (tenanciesResponse) {
      if (tenanciesResponse.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'tenancies',
          tenanciesData: tenanciesResponse.data._embedded ? tenanciesResponse.data._embedded : [],
        })
      }
    })
  }

  getPropertyDocument() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      associatedId: _self.state.propertyId,
      associatedType: 'property',
    }

    axios.get(`${GET_PROPERTY_DOCUMENT_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'document',
          documentData: response.data._embedded ? response.data._embedded : [],
        })
      }
    })
  }

  getPropertyOffer() {
    _self.setState({
      isLoader: true,
    })
    const requestBody = {
      request_to: 'reapit',
      propertyId: _self.state.propertyId,
    }

    axios.get(`${GET_PROPERTY_OFFER_URL}`, {params: requestBody}).then(function (response) {
      if (response.status === 200) {
        _self.setState({
          isLoader: false,
          activeTab: 'offer',
          offerData: response.data._embedded ? response.data._embedded : [],
        })
      }
    })
  }

  getPriceQualifier(qualifier) {
    switch (qualifier) {
      case 'askingPrice':
        return 'Asking Price'
      case 'priceOnApplication':
        return 'POA'
      case 'guidePrice':
        return 'Guide Price'
      case 'offersInRegion':
        return 'Offers in the region of'
      case 'offersOver':
        return 'Offers Over'
      case 'offersInExcess':
        return 'Offers in Excess'
      case 'fixedPrice':
        return 'Fixed Price'
      case 'priceReducedTo':
        return 'Price Reduced to'
    }
  }

  getDownload(event, downloadId, fileName) {
    event.preventDefault()

    const documentTitle = document.getElementById('document_title_' + downloadId)
    const documentLoader = document.getElementById('document_loader_' + downloadId)

    if (documentTitle) {
      documentTitle.style.display = 'none'
    }

    if (documentLoader) {
      documentLoader.style.display = 'block'
    }

    const requestBody = {
      request_to: 'reapit',
      downloadId: downloadId,
      fileName: fileName,
    }

    axios({
      url: GET_PROPERTY_DOWNLOAD_URL,
      params: requestBody,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      if (documentTitle) {
        documentTitle.style.display = 'block'
      }
      if (documentLoader) {
        documentLoader.style.display = 'none'
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  render() {
    const propertyData = this.state.propertyData
    const propertyImageList = this.state.propertyImageList
    const tenanciesData = this.state.tenanciesData
    const offerData = this.state.offerData
    const documentData = this.state.documentData
    console.log(propertyData);
    const propertyAddress = propertyData.address ? propertyData.address : []
    const propertyType = propertyData.letting ? 'For Rent' : 'For Sale'
    const listingLink = propertyData.letting ? '/on_rent' : '/on_sale'
    const propertyPrice = propertyData.letting
      ? propertyData.letting.rent
      : propertyData.selling
      ? propertyData.selling.price
      : 0
    const propertyPriceQualifier = this.getPriceQualifier(
      propertyData.letting
        ? propertyData.letting.rent
        : propertyData.selling
        ? propertyData.selling.qualifier
        : 0
    )
    const propertyPostCode = propertyAddress.postcode
    const propertyCurrency = propertyData.currency
    const propertyDate = new Date(propertyData.created).toLocaleString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })

    return (
      <>
        {this.state.isLoader === true ? (
          <div className='propertyLoader' style={{textAlign: 'center', margin: '50px'}}>
            <Loader type='Oval' color='#B0E670' height={150} width={150} />
          </div>
        ) : (
          <>
          <div className='row'>
                <div className='col-sm-12 col-xl-12 text-center'>
                  <Link to={listingLink} className='btn btn-bg-light'>
                    {'< Back to Search'}
                  </Link>
                </div>
              </div>
            <div className='row g-5 g-xl-8'>

              <div className='card pt-9 pb-0'>
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='col-md-8 me-7 mb-4 border'>
                      <div
                        id='carouselExampleIndicators'
                        className='carousel slide w-100'
                        data-bs-ride='carousel'
                      >
                        <div className='carousel-indicators'>
                          {Object.keys(propertyImageList).length !== 0
                            ? propertyImageList.map((imageInfo, imageKey) => {
                                return (
                                  <button
                                    type='button'
                                    data-bs-target='#carouselExampleIndicators'
                                    data-bs-slide-to={imageKey}
                                    className={imageKey == 0 ? 'active' : ''}
                                    aria-current='true'
                                    aria-label='Slide 1'
                                  ></button>
                                )
                              })
                            : null}
                        </div>
                        <div className='carousel-inner'>
                          {Object.keys(propertyImageList).length !== 0
                            ? propertyImageList.map((imageInfo, imageKey) => {
                                return (
                                  <div
                                    className={imageKey == 2 ? 'carousel-item active' : 'carousel-item'}
                                    key={imageKey}
                                  >
                                    <img src={imageInfo.url} className='d-block w-100' alt='...' />
                                  </div>
                                )
                              })
                            : null}
                        </div>
                        <button
                          className='carousel-control-prev'
                          type='button'
                          data-bs-target='#carouselExampleIndicators'
                          data-bs-slide='prev'
                        >
                          <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                          <span className='visually-hidden'>Previous</span>
                        </button>
                        <button
                          className='carousel-control-next'
                          type='button'
                          data-bs-target='#carouselExampleIndicators'
                          data-bs-slide='next'
                        >
                          <span className='carousel-control-next-icon' aria-hidden='true'></span>
                          <span className='visually-hidden'>Next</span>
                        </button>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                    
                      <h4 className='btn btn-secondary w-100'>{propertyType}</h4>
                      <h2>
                      {propertyAddress.buildingName +
                        ' ' +
                        propertyAddress.buildingNumber +
                        ' ' +
                        propertyAddress.line1 +
                        ' ' +
                        propertyAddress.line2}
                    </h2>

                    <div>
                      <p className='text-muted'>{propertyData.description}</p>
                    </div>
                    </div>
                  </div>
                  
                </div>
              </div>

      
                <div className='row'>
                  <div className='col-md-12 col-xl-12 px-10'>
                    <div className='d-flex flex-wrap mb-5'>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                        <div className='fw-bold text-gray-400'>{propertyPriceQualifier}</div>
                        <div className='fs-6 text-gray-800 fw-bolder'>
                          {'£' + propertyPrice.toLocaleString()}
                        </div>
                        <div className='fw-bold text-gray-400'>per month</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                        <div className='fw-bold text-gray-400'>Post code</div>
                        <div className='fs-6 text-gray-800 fw-bolder'>{propertyPostCode}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                        <div className='fw-bold text-gray-400'>Listed</div>
                        <div className='fs-6 text-gray-800 fw-bolder'>{propertyDate}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                        <div className='fw-bold text-gray-400'>Bedrooms</div>
                        <div className='fs-6 text-gray-800 fw-bolder'>{propertyData.bedrooms}</div>
                      </div>

                      
                    </div>
                  </div>
                </div>
            </div>

            <div className='d-flex flex-column flex-root bg-body w-100 px-10'>
              <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <div className='d-flex flex-row h-300px'>
                    <div className='d-flex flex-column flex-row-fluid'>
                      <div className='row'>
                        <div className='col-sm-8 col-xl-8 px-0'>
                          <ul
                            className='nav nav-tabs nav-line-tabs mb-5 fs-6'
                            style={{backgroundColor: '#fff', borderRadius: '10px'}}
                          >
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'info' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#info'
                              >
                                Description
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'tenancies'
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#map'
                              >
                                Map
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'epc' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#epc'
                              >
                                EPC
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className={
                                  this.state.activeTab === 'epc' ? 'nav-link active' : 'nav-link'
                                }
                                data-bs-toggle='tab'
                                href='#epc'
                              >
                                Video
                              </a>
                            </li>
                          </ul>

                          <div
                            className='tab-content'
                            id='info_container'
                            style={{backgroundColor: '#fff', padding: '10px', borderRadius: '5px'}}
                          >
                            <div className='tab-pane fade active show' id='info' role='tabpanel'>
                              <div className='card mb-5'>
                                <div className='card-body p-0'>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      type Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>type</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      style Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>style</span>
                                    </div>
                                  </div>

                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      bedrooms Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>bedrooms</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      bathrooms Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>bathrooms</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      receptions Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>receptions</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      internalArea Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>internalArea</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      parking Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>parking</span>
                                    </div>
                                  </div>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-mnuted'>
                                      councilTax Label
                                    </label>
                                    <div className='col-lg-8'>
                                      <span className='fw-bolder fs-6 text-dark'>councilTax</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-8 col-xl-8'>
                                  <div className='card-title m-0'>
                                    <div className='fs-3 fw-bolder text-dark'></div>
                                  </div>
                                  <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
                                    {propertyData.longDescription}
                                  </p>

                                  <div className='row'>
                                    <div className='col-md-12 col-xl-12'>
                                      <div className='card-toolbar'>
                                        <span
                                          className={`badge badge-light-${propertyData.badgeColor} fw-bolder me-auto py-3`}
                                        >
                                          {propertyType}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='tab-pane fade' id='map' role='tabpanel'>
                              Output google map
                            </div>
                            <div className='tab-pane fade' id='map' role='tabpanel'>
                              EPC
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-4 col-xl-4 px-0'>
                        <div className='row'>
                        <div className=' col-xl-12 my-3'>
                        <BookViewing />
                        </div>
                        <div className=' col-xl-12 my-3'>
                        <LetOffer/>
                        </div>
                        <div className=' col-xl-12 my-3'>
                          <AskQuestion />
                        </div>
                      </div>
                        </div>
                      </div>
                      
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default withRouter(PropertyDetail)
