/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

var _self: BookViewing

class BookViewing extends Component<any, any> {
  constructor(props: any) {
    super(props)

    _self = this
  }

  render() {
    return (
      <>
        <button
          type='button'
          className='btn btn-primary w-100'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_viewing'
        >
          Book a viewing
        </button>
        <div className='modal fade' tabIndex={-1} id='kt_modal_viewing'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Book Viewing</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  {
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  }
                </div>
              </div>
              <div className='modal-body'>
                <div className='mb-5'>
                  <label className='form-label'>Firstname</label>
                  <input type='text' className='form-control' placeholder='Jeff' />
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Lastname</label>
                  <input type='text' className='form-control' placeholder='Bezos' />
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Email</label>
                  <input type='text' className='form-control' placeholder='Email' />
                </div>
                <div className='mb-5'>
                  <label className='form-label'>Telephone</label>
                  <input type='text' className='form-control' placeholder='Telephone' />
                </div>
                <div className='input-group date'>
                <label className='form-label'>Preferred datetime</label>
                  <input
                    type='text'
                    className='form-control'
                    id='kt_datepicker_2'
                    placeholder='Select date'
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <i className='la la-calendar-check-o'></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='button' className='btn btn-primary'>
                  Submit viewing
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default BookViewing
